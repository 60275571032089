import React from "react"
import "./content.css"
// import { Link } from 'gatsby';
// import business_page_leader_paragraph_icon from '../images/business-page-leader-paragraph-icon.svg';
// import business_page_leader_paragraph_options_icon from '../images/business-page-leader-paragraph-options-icon.svg';
// import student_page_leader_background from '../images/student-page-leader-background.png';
// import schoolpage_content_sharing_knowledge_background from '../images/schoolpage-content-sharing-knowledge-background.png';
// import student_page_how_it_work_background from '../images/student-page-how-it-work-background.png';
// import quote_logo_bar1 from '../images/quote-logo-bar1.svg';
// import quote_logo_bar2 from '../images/quote-logo-bar2.svg';

export class StudentpageContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  setLeaderPart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container businesspage-content-leader-container studentpage-content-leader-container">
          <div className="homepage-content-technology-title">
            Calling Future Leaders of Industry
          </div>
          <div className="homepage-content-technology-description businesspage-content-technology-description studentpage-content-technology-description">
            Discover and connect with inspiring role models and successful
            individuals who will guide you towards a fulfilling career.
            <img
              alt=""
              className="businesspage-content-technology-paragraph-icon"
              src={"/business-page-leader-paragraph-icon.png"}
            />
          </div>
          <div className="homepage-content-technology-paragraph-container businesspage-content-technology-paragraph-container">
            {/* <img
              alt=""
              className="businesspage-content-technology-paragraph-icon"
              src={"/business_page_leader_paragraph_icon"}
            /> */}
            <div className="businesspage-content-technology-paragraph-background">
              <img alt="" src={"/student-page-leader-background.png"} />
            </div>
            <div className="businesspage-content-technology-paragraph-content">
              <div className="businesspage-content-technology-paragraph-text studentpage-content-technology-paragraph-text">
                Speak with and learn from a real rocket scientist, an
                entrepreneur or a game designer.
                <br />
                <br />
                You'll gain invaluable first-hand experience in understanding
                the responsibilities associated with careers of interest, while
                engaging in meaningful discussions about the optimal path to
                enter those fields.
              </div>
              <div className="businesspage-content-technology-paragraph-options student-content-technology-paragraph-options">
                <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                  <img
                    alt=""
                    src={"/business-page-leader-paragraph-options-icon.png"}
                  />
                  <div>Learn first-hand from industry leaders</div>
                </div>
                <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                  <img
                    alt=""
                    src={"/business-page-leader-paragraph-options-icon.png"}
                  />
                  <div>Get introduced to a world of career choices</div>
                </div>
                <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                  <img
                    alt=""
                    src={"/business-page-leader-paragraph-options-icon.png"}
                  />
                  <div>
                    Find the education and training needed to have the career
                    you're passonate about
                  </div>
                </div>
              </div>
              <div className="schoolpage-content-technology-paragraph-button">
                <a
                  href="https://event.tiggbee.com/authentication/createaccount"
                  className="homepage-content-technology-paragraph-button student-content-technology-paragraph-button"
                >
                  Create account
                </a>
                <div className="schoolpage-content-technology-paragraph-button-description studentpage-content-technology-paragraph-button-description">
                  (It's Free!)
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setImpactPart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container businesspage-content-technology-container">
          {/* <div className="businesspage-content-technology-impact studentpage-content-technology-impact">
            Choosing a career path should be a personal decision based on a
            thorough knowledge of your own strengths, weaknesses, interests, and
            values.
          </div> */}
          <div
            className="homepage-content-technology-paragraph-container businesspage-content-impact-paragraph-container studentpage-content-impact-paragraph-container"
            style={{
              backgroundImage: `url(/student-learn-more-background.png)`,
              backgroundSize: "450px 360px",
              backgroundPosition: "right",
            }}
          >
            <div className="homepage-content-technology-paragraph-texts businesspage-content-technology-paragraph-texts studentpage-content-technology-paragraph-texts">
              <div>
                <ul>
                  <li>
                    Discover an exhilarating opportunity to engage with various
                    businesses and gain insights into their industries at
                    Tiggbee! We bring industry professionals and role models
                    together to share their passion, success stories, skill
                    requirements, and career insights.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    With professionally produced video events and interactive
                    projects, you'll immerse yourself in different industries,
                    understanding their intricacies. Learn from experts about
                    emerging trends, challenges, and opportunities in their
                    fields.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Participate in Q&A sessions to interact directly with
                    professionals, seeking personalized insights. At Tiggbee,
                    we're dedicated to fostering connections and empowering you
                    to make informed decisions about your career.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Engage, unlock opportunities, and grow with us! Let's
                    explore, learn, and thrive together!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setQuotePart = () => {
    const content = (
      <>
        <div className="studentpage-quote-container">
          <div className="schoolpage-content-industries-container studentpage-content-industries-container">
            <div className="quote-logo quote-logo1">
              <div>
                <img alt="" src={"/quote-logo-bar1.png"} />{" "}
                <img alt="" src={"/quote-logo-bar2.png"} />{" "}
                <img alt="" src={"/quote-logo-bar1.png"} />{" "}
                <img alt="" src={"/quote-logo-bar2.png"} />
              </div>
              <div>
                <img alt="" src={"/quote-logo-bar1.png"} />{" "}
                <img alt="" src={"/quote-logo-bar2.png"} />{" "}
                <img alt="" src={"/quote-logo-bar1.png"} />{" "}
                <img alt="" src={"/quote-logo-bar2.png"} />
              </div>
            </div>
            <div className="quote-logo quote-logo2">
              <div>
                <img alt="" src={"/quote-logo-bar1.png"} />{" "}
                <img alt="" src={"/quote-logo-bar2.png"} />{" "}
                <img alt="" src={"/quote-logo-bar1.png"} />{" "}
                <img alt="" src={"/quote-logo-bar2.png"} />
              </div>
              <div>
                <img alt="" src={"/quote-logo-bar1.png"} />{" "}
                <img alt="" src={"/quote-logo-bar2.png"} />{" "}
                <img alt="" src={"/quote-logo-bar1.png"} />{" "}
                <img alt="" src={"/quote-logo-bar2.png"} />
              </div>
            </div>
            <div className="schoolpage-content-industrie-item schoolpage-content-industrie-item1">
              "Understand that the right to choose your own path is a sacred
              privilege. Use it. Dwell in possibility"
            </div>
            <div className="studentpage-content-quote-author">
              - Oprah Winfrey
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  setHowItWorkPart = () => {
    const content = (
      <>
        <div className="homepage-content-technology-container businesspage-content-how-it-work-container studentpage-content-how-it-work-container">
          <div className="businesspage-content-how-it-work-background">
            <img alt="" src={"/student-page-how-it-work-background.png"} />
          </div>
          <div className="businesspage-content-how-it-work-guide">
            <div className="businesspage-content-how-it-work-title">
              How it works
            </div>
            <div className="businesspage-content-technology-paragraph-options businesspage-content-how-it-work-paragraph-options studentpage-content-how-it-work-paragraph-options">
              <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>You and your teacher create a free account.</div>
              </div>
              <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>
                  Each week new industry events are featured for your
                  participation.
                </div>
                {/* <div><strong>"Meet My Business Mondays"</strong> - Weekly new industry events are featured for you to participate in.</div> */}
              </div>
              <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>
                  Get inspired and interact with amazing featured presenters.
                </div>
              </div>
              <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>Ask industry experts questions.</div>
              </div>
              <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>Receive and watch informative video responses.</div>
              </div>
              <div className="businesspage-content-technology-paragraph-option studentpage-content-technology-paragraph-option">
                <img
                  alt=""
                  src={"/business-page-leader-paragraph-options-icon.png"}
                />
                <div>Keep discovering new career opportunities each week.</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return content
  }

  render() {
    const html = (
      <div className="student-page-content">
        {this.setLeaderPart()}
        {this.setImpactPart()}
        {this.setQuotePart()}
        {this.setHowItWorkPart()}
      </div>
    )
    return html
  }
}
