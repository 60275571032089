import React from "react"
import "./content.css"

export class PrivacyPolicyContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  setPageHeading = () => {
    return (
      <>
        <div className="policy-header-div">
          <h1>TIGGBEE LLC</h1>
          <h1>PRIVACY POLICY</h1>
          <div>Last Updated: 01/6/2025</div>
        </div>
        <br />
        <br />
        <p>
          This privacy policy (“<strong>Policy</strong>”) describes how Tiggbee
          LLC and its related companies (“<strong>Company</strong>”) collect,
          use and share personal information of consumer users of this website,
          www.tiggbee.com (the “<strong>Site</strong>”). This Policy also
          applies to any of our other websites that post this Policy. This
          Policy does not apply to websites that post different statements.
        </p>
      </>
    )
  }

  setContent = () => {
    return (
      <>
        <h2>WHAT WE COLLECT</h2>
        <p>We get information about you in a range of ways.</p>
        <div className="policy-sub-p-div">
          <p>
            <strong>Information You Give Us.</strong> We collect your name,
            postal address, email address, phone number, username, password as
            well as other information you directly give us on our Site.
          </p>
          <p>
            <strong>Information We Get From Others.</strong> We may get
            information about you from other sources. We may add this to
            information we get from this Site.
          </p>
          <p>
            <strong>Information Automatically Collected.</strong> We
            automatically log information about you and your computer. For
            example, when visiting our Site, we log your computer operating
            system type, browser type, pages you viewed, how long you spent on a
            page, access times and information about your use of and actions on
            our Site.
          </p>
          <p>
            <strong>Cookies.</strong> We may log information using "cookies."
            Cookies are small data files stored on your hard drive by a website.
            We may use both session Cookies (which expire once you close your
            web browser) and persistent Cookies (which stay on your computer
            until you delete them) to provide you with a more personal and
            interactive experience on our Site. This type of information is
            collected to make the Site more useful to you and to tailor the
            experience with us to meet your special interests and needs.
          </p>
        </div>
        <br />
        <h2>USE OF PERSONAL INFORMATION</h2>
        <p>"Personally Identifiable Information" (i.e., information that can be used to identify you may also be referred to as “personal data” or “personal information”). Personally Identifiable Information can include information such as your name, email address, IP address, and device identifier.</p>
        <p>We use your personal information as follows:</p>
        <div className="policy-sub-p-div">
          <ul>
            <li>
              <p>
                We use your personal information to respond to comments and questions and provide customer service.
              </p>
            </li>
            <li>
              <p>
                We use your personal information to send information including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.
              </p>
            </li>
            <li>
              <p>
                We do not use student identifiable information for commercial purposes, including advertising or marketing. Student identifiable personal information will not be sold, rented, or shared for any commercial or marketing purposes.
              </p>
            </li>
          </ul>
        </div>
        <p>
          You should not include any Personally Identifiable Information or
          other information of a personal or sensitive nature, whether relating
          to you or another person, while participating in any events (including
          any discussions, question-and-answer sessions, etc.).
          <br />
          We keep your Personally Identifiable Information for no longer than
          necessary for the purposes for which the Personally Identifiable
          Information is collected and processed. The length of time we retain
          Personally Identifiable Information for depends on the purposes for
          which we collect and use it and/or as required to comply with
          applicable laws and to establish, exercise, or defend our legal
          rights.
        </p>
        <p>
          Because the Internet is not a 100% secure environment, we cannot
          guarantee the security of Personally Identifiable Information, and
          there is some risk that an unauthorized third party may find a way to
          circumvent our security systems or that transmission of your
          information over the Internet will be intercepted. It is your
          responsibility to protect the security of your login information.
          Please note that e-mails communications are typically not encrypted
          and should not be considered secure.
        </p>
        <br />
        <h2>SHARING OF PERSONAL INFORMATION</h2>
        <p>We do not sell identifiable personal information.</p>
        <p>We may share personal information as follows:</p>
        <div className="policy-sub-p-div">
          <ul>
            <li>
              <p>
                We may share personal information when we do a business deal, or
                negotiate a business deal, involving the sale or transfer of all
                or a part of our business or assets. These deals can include any
                merger, financing, acquisition, or bankruptcy transaction or
                proceeding.
              </p>
            </li>
            <li>
              <p>
                We may share personal information for legal, protection, and
                safety purposes.
              </p>
              <div className="policy-sub-p-div">
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    <p>We may share information to comply with laws.</p>
                  </li>
                  <li>
                    <p>
                      We may share information to respond to lawful requests and
                      legal processes.
                    </p>
                  </li>
                  <li>
                    <p>
                      We may share information to protect the rights and
                      property of Tiggbee LLC, our agents, customers, and
                      others. This includes enforcing our agreements, policies,
                      and terms of use.
                    </p>
                  </li>
                  <li>
                    <p>
                      We may share information in an emergency. This includes
                      protecting the safety of our employees and agents, our
                      customers, or any person.
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p>
                We may store your information with those who need it to do work
                for us.
              </p>
            </li>
          </ul>
        </div>
        <p>
          We may also share aggregated and/or anonymized data with others for
          their own uses.
        </p>
        <br />
        <h2>INFORMATION CHOICES AND CHANGES</h2>
        <p>
          Our marketing emails tell you how to “opt-out.” If you opt out, we may
          still send you non-marketing emails. Non-marketing emails include
          emails about your accounts and our business dealings with you.
        </p>
        <p>
          You may send requests about personal information to our Contact
          Information below. You can request to change contact choices, opt-out
          of our sharing with others, and update your personal information.
        </p>
        <p>
          You can typically remove and reject cookies from our Site with your
          browser settings. Many browsers are set to accept cookies until you
          change your settings. If you remove or reject our cookies, it could
          affect how our Site works for you.
        </p>
        <p>
          You have certain rights in relation to your Personally Identifiable Information. You can access your Personally
          Identifiable Information and confirm that it remains correct and up to date, choose whether or not you wish to
          receive material from us or some of our partners, and request that we delete or provide you with a copy of your
          personal data by logging into the Site and visiting your user account page. We retain student data only for as long as
          necessary to fulfill the educational purposes for which it was collected, or as required by applicable law. Once it is
          no longer necessary for those purposes, student data will be securely deleted.
        </p>
        <p>
          <ins>California Privacy Rights</ins>
        </p>
        <p>
          Under California’s "Shine the Light" law, California residents who
          provide personal information in obtaining products or services for
          personal, family, or household use are entitled to request and obtain
          from us, once a calendar year, information about the customer
          information we shared, if any, with other businesses for their own
          direct marketing uses. If applicable, this information would include
          the categories of customer information and the names and addresses of
          those businesses with which we shared customer information for the
          immediately prior calendar year.
        </p>
        <p>
          To obtain this information, please send an email message to{" "}
          <a href="mailto:support@tiggbee.com">support@tiggbee.com</a> with
          "Request for California Privacy Information" on the subject line and
          in the body of your message. We will provide the requested information
          to you at your email address in response. Please be aware that not all
          information sharing is covered by the "Shine the Light" requirements
          and only information on covered sharing will be included in our
          response.
        </p>
        <p>
          Under the California Consumer Privacy Act (“CCPA”), California
          residents have the right to know what personal information about them
          is collected, request deletion of their personal data, opt-out of the
          sale of their personal data, and not be discriminated against if they
          choose to exercise any of these rights. We do not sell any of the data
          we collect about you. If you’d like to exercise any of the other
          rights afforded to you, contact us at{" "}
          <a href="mailto:support@tiggbee.com">support@tiggbee.com</a>.
        </p>
        <br />
        <p>
          <span className="policy-h-span">CONTACT INFORMATION.</span> We welcome
          your comments or questions about this privacy policy. You may also
          contact us at our address:
        </p>
        <p>
          Tiggbee LLC
          <br />
          22431 Antonio Pkwy B160-202
          <br />
          Rancho Santa Margarita, California 92688
        </p>
        <p>
          <a href="mailto:support@tiggbee.com">support@tiggbee.com</a>
        </p>
        <br />
        <p>
          <span className="policy-h-span">CHANGES TO THIS PRIVACY POLICY.</span>{" "}
          We may change this privacy policy. If we make any changes, we will
          change the Last Updated date above.
        </p>
      </>
    )
  }

  render() {
    const html = (
      <>
        <div className="policy-container-div">
          <br /> <br /> <br />
          {this.setPageHeading()}
          <br />
          {this.setContent()}
          <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
          <br /> <br />
        </div>
      </>
    )
    return html
  }
}
